import React, { useEffect }  from 'react';
import './App.css';
import CookieConsent, { getCookieConsentValue, Cookies, } from "react-cookie-consent";
import firebase from "firebase/app";
import Shopper from "./component/shopper";


function App() {

    const handleAcceptCookie = () => {
        firebase.analytics();
    };

    const handleDeclineCookie = () => {
        // remove google analytics cookies
        Cookies.remove("_ga");
        Cookies.remove("_gat");
        Cookies.remove("_gid")
    };

    useEffect(() => {
       const isConsent = getCookieConsentValue();
       if (isConsent === "true") {
           handleAcceptCookie();
       }
    }, []);

    return (
        <div className="dark-theme App-wrapper">
            <div className="App">
                <Shopper/>
            </div>
            <CookieConsent enableDeclineButton onAccept={handleAcceptCookie} onDecline={handleDeclineCookie}>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
            <script src="/__/firebase/8.4.1/firebase-app.js"/>
            <script src="/__/firebase/init.js"/>
        </div>

    );
}

export default App;
