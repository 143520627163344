import * as actionTypes from "./actions"
import {
    ArticleAdditionAction,
    ArticleDeletionAction,
    LoginLoggedInAction,
    LoginLoggedOutAction,
    PageAction
} from "./actions"
import {PageState} from "./storeInterfaces";
import * as reducerActions from "./reducerActions";

const initialState: PageState = {
    articles: {
        articles: [
            {
                id: 1,
                title: "post 1",
                body:
                    "Quisque cursus, metus vitae pharetra Nam libero tempore, cum soluta nobis est eligendi",
            },
            {
                id: 2,
                title: "post 2",
                body:
                    "Harum quidem rerum facilis est et expedita distinctio quas molestias excepturi sint",
            },
        ]
    },
    login: {
        isLoggedIn: false
    }
}

const reducer = (
    state: PageState = initialState,
    rawAction: PageAction
): PageState => {
    console.log('reducing event!', rawAction)
    switch (rawAction.type) {
        case actionTypes.ARTICLE_ADD: {
            return reducerActions.handleArticleAdd(state, rawAction as ArticleAdditionAction);
        }
        case actionTypes.ARTICLE_REMOVE: {
           return reducerActions.handleArticleDeletion(state, rawAction as ArticleDeletionAction);
        }
        case actionTypes.LOGIN_LOGIN : {
            return reducerActions.handleLogin(state, rawAction as LoginLoggedInAction);
        }
        case actionTypes.LOGIN_LOGOUT: {
            return reducerActions.handleLogout(state, rawAction as LoginLoggedOutAction);
        }
        default : {
            if (!rawAction.type.startsWith('@@redux/')) {
                console.error("Unhandled action: ", rawAction);
            }
        }
    }
    return state
}


export default reducer