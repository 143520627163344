import {IArticleInput} from "./storeInterfaces";

export type DispatchType = (args: PageAction) => PageAction

export interface PageAction {
    type : string
}

export interface ArticleAction extends PageAction {
}
export interface ArticleAdditionAction extends ArticleAction {
    article: IArticleInput
}
export interface ArticleDeletionAction extends ArticleAction {
    articleId: number
}

export interface LoginAction extends PageAction {}

export interface LoginLoggedInAction extends LoginAction {
    googleResponse: gapi.auth2.GoogleUser
}
export interface LoginLoggedOutAction extends LoginAction {}



export const ARTICLE_ADD = "ARTICLE_ADD"
export const ARTICLE_REMOVE = "ARTICLE_REMOVE"
export const LOGIN_LOGIN = "LOGIN"
export const LOGIN_LOGOUT = "LOGOUT"
