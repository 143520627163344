import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";
import "firebase/analytics";
import { createStore, applyMiddleware, Store } from "redux"
import { Provider } from 'react-redux';
import thunk from "redux-thunk"
import reducer from "./redux/reducers"
import {PageState} from "./redux/storeInterfaces";
import {DispatchType, PageAction} from "./redux/actions";

// interface LoginAction {
//     type : "login" | "logout"
//     googleResponse? : gapi.auth2.GoogleUser
// }
//
// interface UserDetails {
//
// }
// const userDetailsReducer = function getUserDetails(currentDetails : UserDetails, action : LoginAction) : UserDetails {
//     switch (action.type) {
//         case "login" :
//             return {}
//         case "logout" :
//             return {}
//         default: {D
//             console.log("BIG BOO BOO")
//             return {}
//         }
//     }
// }

const store: Store<PageState, PageAction> & {
    dispatch: DispatchType
} = createStore(reducer, applyMiddleware(thunk))


ReactDOM.render(
    <Provider store={store}>
        <div className="bodyWrapper">
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </div>
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


const firebaseConfig = {
    apiKey: "AIzaSyDHIexmxUG3qYXTs3L0llXW1CdrpD0Jzo8",
    authDomain: "shopping-scanner-325315.firebaseapp.com",
    projectId: "shopping-scanner-325315",
    storageBucket: "shopping-scanner-325315.appspot.com",
    messagingSenderId: "28159387702",
    appId: "1:28159387702:web:4ea8c7acbada0f3ce4d097",
    measurementId: "G-NRK9G5H81D"
};

firebase.initializeApp(firebaseConfig);
