import * as actionTypes from "./actions"
import {IArticleInput} from "./storeInterfaces";
import {
    ArticleAction,
    ArticleAdditionAction,
    ArticleDeletionAction,
    DispatchType,
    LoginLoggedInAction
} from "./actions";

export function addArticle(article: IArticleInput) {
    const action: ArticleAdditionAction = {
        type: actionTypes.ARTICLE_ADD,
        article: article,
    }

    return simulateHttpRequest(action)
}

export function removeArticle(article: number) {
    const action: ArticleDeletionAction = {
        type: actionTypes.ARTICLE_REMOVE,
        articleId: article
    }
    return simulateHttpRequest(action)
}

export function simulateHttpRequest(action: ArticleAction) {
    return (dispatch: DispatchType) => {
        setTimeout(() => {
            dispatch(action)
        }, 500)
    }
}



export function doLogin(googleResponse : gapi.auth2.GoogleUser) {
    return (dispatch:DispatchType) => {
        dispatch({
            type: actionTypes.LOGIN_LOGIN,
            googleResponse: googleResponse
        } as LoginLoggedInAction)
    }
}

export function doLogout() {
    return (dispatch:DispatchType) => {
        dispatch({
            type: actionTypes.LOGIN_LOGOUT
        })
    }
}