import {IArticle, LoggedInState, LoginState, PageState} from "./storeInterfaces";
import {ArticleAdditionAction, ArticleDeletionAction, LoginLoggedInAction, LoginLoggedOutAction} from "./actions";

export const handleArticleAdd = (state : PageState, action: ArticleAdditionAction) => {
    const newArticle: IArticle = {
        id: Math.random(), // not really unique
        title: action.article.title,
        body: action.article.body,
    }
    return {
        ...state,
        articles: {
            articles: state.articles.articles.concat(newArticle)
        },
    }
}

export const handleArticleDeletion = (state : PageState, action: ArticleDeletionAction) => {
    const updatedArticles: IArticle[] = state.articles.articles.filter(
        article => article.id !== action.articleId
    )
    return {
        ...state,
        articles: {
            ...state.articles,
            articles: updatedArticles
        },
    }
}

export const handleLogin = (state : PageState, action: LoginLoggedInAction) => {
    const basicProfile = action.googleResponse.getBasicProfile();
    return {
        ...state,
        login: {
            isLoggedIn: true,
            userDetails: {
                    id_token : action.googleResponse.getAuthResponse().id_token,
                    id: basicProfile.getId(),
                    name: basicProfile.getName(),
                    givenName: basicProfile.getGivenName(),
                    familyName: basicProfile.getFamilyName(),
                    avatarUrl: basicProfile.getImageUrl(),
                    email: basicProfile.getEmail(),
            }
        } as LoggedInState
    }
}

export const handleLogout = (state : PageState, action: LoginLoggedOutAction) => {
    return {
        ...state,
        login : {
            isLoggedIn: false,
            ...state,
            googleResponse: undefined
        } as LoginState
    }
}