import React, {Component} from "react";
import "./pagecontent.css"
import GoogleLogin, {GoogleLogout} from "react-google-login";
import {IArticle, IArticleInput, LoggedInState, LoginState} from "../redux/storeInterfaces";
import {addArticle, doLogin, doLogout, removeArticle} from "../redux/actionCreators";
import {connect} from "react-redux";

interface PageContentProps {
    articles: readonly IArticle[],
    loginState: LoginState
    addArticle : (a : IArticleInput) => void
    removeArticle : (a : number) => void
    doLogin : (a : gapi.auth2.GoogleUser) => void
    doLogout : () => void
}

interface PageContentState {
    articleDetails? : IArticleInput
    deletionDetails?: number
}

class PageContent extends Component<PageContentProps, PageContentState> {

    constructor(props: PageContentProps) {
        super(props);
        this.state = {};
        this.onGoogleFailure = this.onGoogleFailure.bind(this);
        this.onGoogleSuccess = this.onGoogleSuccess.bind(this);
        this.onLogoutSuccess = this.onLogoutSuccess.bind(this);
        this.addNewArticle = this.addNewArticle.bind(this);
        this.deleteSpecifiedArticle = this.deleteSpecifiedArticle.bind(this);
        this.addDetailsPopulate = this.addDetailsPopulate.bind(this);
        this.deleteDetailsPopulate = this.deleteDetailsPopulate.bind(this);
    }

    addDetailsPopulate() {
        this.setState({
            ...this.state,
            articleDetails: {
                body: "Body Value" + Math.random(),
                title: "Random Title " + Math.random()
            }
        })
    }

    deleteDetailsPopulate() {
        this.setState({
            ...this.state,
            deletionDetails: this.props.articles.length > 0 ? this.props.articles[0].id : undefined
        });
    }

    addNewArticle() {
        this.props.addArticle(this.state.articleDetails as IArticleInput);
        this.setState({
            ...this.state,
            articleDetails: undefined
        });
    }

    deleteSpecifiedArticle() {
        this.props.removeArticle(this.state.deletionDetails as number);
        this.setState({
            ...this.state,
            deletionDetails: undefined
        });
    }

    //TODO  secret: e4_y5a2ud8y0sHKEUBAW4bqn

    onGoogleSuccess(obj: any) {
        console.log("succ", obj);
        this.props.doLogin(obj as gapi.auth2.GoogleUser)
    }

    onGoogleFailure(obj: any) {
        console.log("Error when attempting to login via google. Error Type: ", obj.error, obj.idpId, obj.type);
    }

    onLogoutSuccess() {
        console.log('logout!');
        this.props.doLogout();
    }


    render() {
        return (
            // https://www.npmjs.com/package/react-google-login
            <div className="PageContent">
                <GoogleLogin
                    clientId="28159387702-bpj130bam7rbdm445e6oob0k43orahuu.apps.googleusercontent.com"
                    buttonText="Login"
                    onSuccess={this.onGoogleSuccess}
                    onFailure={this.onGoogleFailure}
                    // render={renderProps => (
                    //     <button onClick={renderProps.onClick} disabled={renderProps.disabled}>This is my custom Google button</button>
                    // )}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={this.props.loginState.isLoggedIn} // this will call onSuccess on load to keep user signed in
                />
                <br/>
                <GoogleLogout
                    clientId="28159387702-bpj130bam7rbdm445e6oob0k43orahuu.apps.googleusercontent.com"
                    buttonText="Logout"
                    onLogoutSuccess={this.onLogoutSuccess}
                />

                <div>
                    <h1>user details</h1>
                    <span>{this.props.loginState.isLoggedIn ? "Logged In" : "Logged Out"}</span>
                    {this.props.loginState.isLoggedIn &&
                    <div>
                        <table>
                            <tr>
                                <th>Field</th>
                                <th>Value</th>
                            </tr>
                            <tr>
                                <td>ID Token:</td>
                                <td>{((this.props.loginState) as LoggedInState).userDetails.id_token}</td>
                            </tr>
                            <tr>
                                <td>ID:</td>
                                <td>{((this.props.loginState) as LoggedInState).userDetails.id}</td>
                            </tr>
                            <tr>
                                <td>Name:</td>
                                <td>{((this.props.loginState) as LoggedInState).userDetails.name}</td>
                            </tr>
                            <tr>
                                <td>Given Name:</td>
                                <td>{((this.props.loginState) as LoggedInState).userDetails.givenName}</td>
                            </tr>
                            <tr>
                                <td>Family Name:</td>
                                <td>{((this.props.loginState) as LoggedInState).userDetails.familyName}</td>
                            </tr>
                            <tr>
                                <td>Avatar:</td>
                                <td><img alt="avatarImg" src={((this.props.loginState) as LoggedInState).userDetails.avatarUrl}/></td>
                            </tr>
                            <tr>
                                <td>Email:</td>
                                <td>{((this.props.loginState) as LoggedInState).userDetails.email}</td>
                            </tr>

                        </table>
                    </div>}
                </div>

                <hr/>
                <hr/>
                <br/><br/><br/>

                <button onClick={this.deleteDetailsPopulate}>Delete Details Populate</button>
                <button onClick={this.addDetailsPopulate}>Add Details Populate</button>
                <br/>
                {this.state.deletionDetails && <div>
                    <span>Ready to Delete: {this.state.deletionDetails}</span> <button onClick={this.deleteSpecifiedArticle}>Delete</button>
                </div>}
                <br/>
                {this.state.articleDetails && <div>
                <span>Ready to Add: ({this.state.articleDetails.title + " - " + this.state.articleDetails.body})</span> <button onClick={this.addNewArticle}>Add</button>
                </div>}


                <h1>Articles</h1>
                {this.props.articles.map(a => <div id={"article_" + a.id}>
                    <span>Article {a.id}){a.title} - {a.body}</span>
                    <hr/>
                </div>)}

                <br/>

                <div>
                    <span>Like this page?</span>
                    <form action="https://www.paypal.com/donate" method="post" target="_top">
                        <input type="hidden" name="business" value="5PMS9L7X9H9VE" />
                        <input type="hidden" name="no_recurring" value="0" />
                        <input type="hidden" name="item_name" value="Malta Saver Donations" />
                        <input type="hidden" name="currency_code" value="EUR" />
                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                    </form>
                </div>
            </div>);

    }
}

export default connect(null, {addArticle, removeArticle, doLogin, doLogout})(PageContent)