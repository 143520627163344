import React, {Component} from "react";
import './shopper.css'
import PageContent from "./pagecontent";
import {IArticle, LoginState, PageState} from "../redux/storeInterfaces";
import {connect} from "react-redux";

const mapStateToProps = (state : PageState) => {
    return {
        articles: state.articles.articles,
        loginState: state.login
    }
}

interface ShopperProps {
    articles: IArticle[],
    loginState : LoginState
}

class Shopper extends Component<ShopperProps> {

    constructor(props : ShopperProps) {
        super(props)
    }

    render() {
        return (
            <div className="Shopper-Wrapper">
                {/*<header>*/}
                {/*    <Header/>*/}
                {/*</header>*/}
                <PageContent
                    articles={this.props.articles}
                    loginState={this.props.loginState}
                />
            </div>);
    }
}
export default connect(mapStateToProps, {})(Shopper)